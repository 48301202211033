import router from "@/router/index.js";
import { useAppointmentsStore } from "@/stores/appointments.ts";
import { useDocumentsStore } from "@/stores/documents.ts";
import { useFundamentalStore } from "@/stores/fundamental.ts";
import { useProceedingsStore } from "@/stores/proceedings.ts";
import { useTasksStore } from "@/stores/tasks.ts";

export function setPageTitle(routeData) {
  const proceedingsStore = useProceedingsStore();
  const baseTitle = "DiPlanung Cockpit Basis";
  const separator = " - ";
  const subSeparator = " - ";
  const proceedingPlaceholder = "{proceedingName}";
  let currentTitle = baseTitle;

  if (routeData.meta && routeData.meta.title) {
    if (typeof routeData.meta.title === "string") {
      currentTitle = routeData.meta.title + separator + baseTitle;
    } else if (Array.isArray(routeData.meta.title)) {
      currentTitle = routeData.meta.title.join(subSeparator) + separator + baseTitle;
    }
  }

  // Set proceeding name
  if (currentTitle.includes(proceedingPlaceholder)) {
    if (routeData.params.planID && proceedingsStore.proceedingContainer[routeData.params.planID]) {
      const proceedingData = proceedingsStore.proceedingContainer[routeData.params.planID];

      const proceedingName = proceedingData.workingCopy?.planname;
      const proceedingArbeitstitel = proceedingData.workingCopy?.arbeitstitel;
      const displayName = proceedingName ? proceedingName : proceedingArbeitstitel;

      currentTitle = currentTitle.replace(
        proceedingPlaceholder,
        proceedingData.subscribed ? displayName : "Nicht abonniert: " + displayName,
      );
    } else {
      currentTitle = baseTitle;
    }
  }

  document.title = currentTitle;
}

export async function GetNutzerResolver() {
  // get user information
  // /nutzer
  // getNutzer
  const fundamentalStore = useFundamentalStore();

  return fundamentalStore.loadUserSettings();
}

export async function GetKonfigurationenVerfahrenResolver() {
  // get (all) proceeding configurations
  // GET /konfigurationen/verfahren
  // getAll
  const fundamentalStore = useFundamentalStore();

  return fundamentalStore.loadProceedingStructures();
}

export async function GetKonfigurationenCodelistenResolver() {
  // get all codelists
  // GET /konfigurationen/codelisten
  // getAllCodelisten
  const fundamentalStore = useFundamentalStore();

  return fundamentalStore.loadConfigurationCodelists();
}

export async function GetKonfigurationenBlacklistResolver() {
  // get the blacklist
  // GET /konfigurationen/blacklist
  // getBlacklist
  const fundamentalStore = useFundamentalStore();

  return fundamentalStore.loadDateBlacklist();
}

export async function GetKonfigurationenWhitelistSerieResolver() {
  // get the whitelist serie
  // GET /konfigurationen/whitelistserie
  // getWhitelistSerie
  const fundamentalStore = useFundamentalStore();

  return fundamentalStore.loadDateWhitelistSeries();
}

export async function GetKonfigurationenRechtszitateResolver() {
  // get the rechtszitate
  // GET /konfigurationen/rechtszitate
  // getAllRechtszitate
  const fundamentalStore = useFundamentalStore();

  return fundamentalStore.loadRechtszitate(true);
}

export async function GetNutzerVerfahrenResolver() {
  // get IDs of all subscribed proceedings
  // GET /nutzer/verfahren
  // getNutzerVerfahren
  const proceedingsStore = useProceedingsStore();

  return proceedingsStore.loadSubscribedProceedingIDs(true);
}

export async function GetVerfahrenResolver() {
  // get meta data of all subscribed proceedings
  // GET /verfahren
  // getAllVerfahren
  const proceedingsStore = useProceedingsStore();

  return proceedingsStore.loadSubscribedProceedings(true);
}

export async function GetVerfahrenDetailResolver(params) {
  // get proceeding detail
  // GET /verfahren/{planID}
  // getVerfahrenDetail
  const proceedingsStore = useProceedingsStore();
  const { planID } = params;

  return proceedingsStore.loadProceedingByID(planID, true);
}
export async function GetVerfahrenZeitplanungResolver(params) {
  // get proceeding schedule
  // GET /verfahren/{planID}/zeitplanung
  // getZeitplanung
  const appointmentsStore = useAppointmentsStore();
  const fundamentalStore = useFundamentalStore();
  const { planID } = params;

  if (fundamentalStore.isProUser) {
    return appointmentsStore.loadScheduleRelevantDataByID(planID, true);
  }
  return Promise.resolve(undefined);
}
export async function GetVerfahrenVerfahrenteilsschritteuebersichtResolver(params) {
  // get proceeding structure
  // getVerfahrensteilschritteUebersicht()
  // GET /verfahren/{planID}/verfahrensteilschritteuebersicht
  const tasksStore = useTasksStore();
  const fundamentalStore = useFundamentalStore();
  const { planID } = params;

  if (fundamentalStore.isProUser) {
    return tasksStore.loadVtsOverview(planID, true);
  }
  return Promise.resolve(undefined);
}

export async function GetVerfahrenVerfahrenteilschrittResolver(params) {
  // get proceeding vts data
  // getVerfahrensteilschrittDetail()
  // GET /verfahren/{planID}/verfahrensteilschritte/{code}
  const tasksStore = useTasksStore();

  const fundamentalStore = useFundamentalStore();
  const { planID, vtsID } = params;

  if (fundamentalStore.isProUser) {
    return tasksStore.loadVtsData(vtsID, true, planID);
  }
  return Promise.resolve(undefined);
}

export async function GetVorlagenUebersichtResolver() {
  // get document templates
  // getVorlageUebersicht()
  // GET vorlagen/uebersicht
  const documentsStore = useDocumentsStore();

  return documentsStore.loadDocumentTemplates(true);
}

export async function GetVerfahrenDokumenteResolver(params) {
  // get all proceeding documents
  // getDokumentUebersicht()
  // /verfahren/{planID}/dokumente
  const documentsStore = useDocumentsStore();
  const { planID } = params;

  return documentsStore.loadProceedingDocuments({ proceedingID: planID });
}

export async function GetVerfahrenVertragsdokumenteResolver(params) {
  // get proceeding documents of type contracts
  // getVertragsdokumente()
  //  /verfahren/{planID}/vertragsdokument
  const documentsStore = useDocumentsStore();
  const { planID } = params;

  return documentsStore.loadProceedingContractsOnly(planID);
}

export async function GetVerfahrenDokumentePlanwerkeResolver(params) {
  // get all proceeding documents of type "planwerke"
  // getDokumentUebersicht()
  // /verfahren/{planID}/dokumente
  const documentsStore = useDocumentsStore();
  const { planID } = params;

  return documentsStore.loadProceedingDocuments({
    proceedingID: planID,
    params: { codeVerfahrensunterlagetyp: ["0350"] },
    xplan: true,
  }); //0350 = Planwerk
}

/**
 * Function to check if user has the permission to route
 * @param {Array} requiredPermissions
 * */
export function userHasPermissions(requiredPermissions) {
  const fundamentalStore = useFundamentalStore();

  if (fundamentalStore.userSettings) {
    const userRights = fundamentalStore.userSettings?.rechte;

    return requiredPermissions.some((permission) => userRights.includes(permission));
  }

  return false;
}

/**
 * @returns {string|undefined}
 */
export function getPlanID() {
  return router.currentRoute.value.params.planID !== undefined
    ? router.currentRoute.value.params.planID.toString()
    : undefined;
}
