import { openAPIFactory } from "@/services/open-api.js";

/**
 * function to POST a contract
 * @param {Object} document - VertragsdokumentNeuRest
 * @param {File} file - binary file
 * @param {String} planID - proceeding ID
 * @return {Promise<VertragsdokumentRest>}
 */
export function createContract(document, file, planID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .vertragsdokumentResourceApiFactory()
      .createVertragsdokument(document, file, planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * function to POST a contract addition to an existing contract
 * @param {Object} document - VertragsdokumentNeuRest
 * @param {File} file - binary file
 * @param {String} planID - proceeding ID
 * @param {String} fileID - ID of contract
 * @return {Promise<unknown>}
 */
export function createContractAddition(document, file, planID, fileID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .vertragsdokumentResourceApiFactory()
      .createVertragsdokumentErgaenzung(document, file, planID, fileID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * function to update contract details
 * @param {Object} document - VertragsdokumentNeuRest
 * @param {String} planID - proceeding ID
 * @param {String} fileID - ID of contract
 * @param {File} file - OPTIONAL binary file
 * @return {Promise<unknown>}
 */
export function updateContract(document, planID, fileID, file = null) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .vertragsdokumentResourceApiFactory()
      .updateVertragsdokument(document, planID, fileID, file)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * function to update contract addition details
 * @param {Object} document - VertragsdokumentNeuRest
 * @param {String} planID - proceeding ID
 * @param {String} vertragsdokumentFileID - ID of corresponding contract
 * @param {String} fileID - ID of contract addition
 * @param {File} file - OPTIONAL binary file
 * @return {Promise<unknown>}
 */
export function updateContractAddition(
  document,
  planID,
  vertragsdokumentFileID,
  fileID,
  file = null,
) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .vertragsdokumentResourceApiFactory()
      .updateVertragsdokumentErgaenzung(document, planID, vertragsdokumentFileID, fileID, file)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * function to sort all additions in correct order behind matching contract
 * @param contracts {array} - array of object
 * @param additions {array} - array of object
 * @returns {[array]} - return sorted list
 */
export function getSortedContractsAndAdditions(contracts, additions) {
  const sortedContractsAndAdditions = [];
  const sortedContracts = getSortedContractsByVertragsartAndVersion(contracts);

  sortedContracts.forEach((contract) => {
    const children = additions.filter(
      (addition) => addition.vertragsdokumentFileID === contract.fileID,
    );

    const sortedAdditions = getSortedAdditionsByVersion(children);

    sortedContractsAndAdditions.push(contract);
    sortedContractsAndAdditions.push(...sortedAdditions);
    const childrenIDs = sortedAdditions.map((child) => child.fileID);

    additions = additions.filter((addition) => !childrenIDs.includes(addition.fileID));
  });

  if (additions.length) {
    sortedContractsAndAdditions.push(...additions);
  }

  return sortedContractsAndAdditions;
}

/**
 * Sort additions by versionsNummer
 * @param contracts - Array<VertragsdokumentRest>
 */
export function getSortedAdditionsByVersion(additions) {
  return additions.toSorted((a, b) => {
    // sort by versionsNummer
    if (
      a.versionen[a.versionen.length - 1].versionsNummer <
      b.versionen[a.versionen.length - 1].versionsNummer
    )
      return -1;
    if (
      a.versionen[a.versionen.length - 1].versionsNummer >
      b.versionen[a.versionen.length - 1].versionsNummer
    )
      return 1;
    return 0;
  });
}

/**
 * Sort contracts by vertragsart and by versionsNummer
 * @param contracts - Array<VertragsdokumentRest>
 */
export function getSortedContractsByVertragsartAndVersion(contracts) {
  return contracts.toSorted((a, b) => {
    // 1. priority - sort by vertragsart
    if (a.vertragsart.name < b.vertragsart.name) return -1;
    if (a.vertragsart.name > b.vertragsart.name) return 1;
    // 2. priority - sort by versionsNummer
    if (
      a.versionen[a.versionen.length - 1].versionsNummer <
      b.versionen[a.versionen.length - 1].versionsNummer
    )
      return -1;
    if (
      a.versionen[a.versionen.length - 1].versionsNummer >
      b.versionen[a.versionen.length - 1].versionsNummer
    )
      return 1;
    return 0;
  });
}
