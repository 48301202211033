import { defineStore } from "pinia";

import { getPlanID } from "@/router/services.js";
import { getSortedContractsAndAdditions } from "@/services/contracts.js";
import type {
  CodeVerfahrensunterlagetyp,
  CodeVormerkung,
  DokumentRest,
  FindActionItem200Response,
  GetDokumentDetail200Response,
  GetDokumentUebersichtKlassifizierung,
  PlanInfoBbox,
  ValidationReportRest,
  ValidationReportValidationResult,
  VerfahrenDokumentRest,
  VertragsdokumentRest,
  VertragsErgaenzungsdokumentRest,
  VorlageRest,
  XplanManagerStatusMessageRest,
} from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.ts";
import { CodeDescription, useFundamentalStore } from "@/stores/fundamental.ts";

export interface ValidationReport extends ValidationReportRest {
  fileID: string;
}

export interface DocumentsStoreState {
  documentTemplates: VorlageRest[];
  documentContainer: {
    [key: string]: {
      loading: boolean;
      documents: DokumentRest[];
      contracts: VertragsdokumentRest[];
      contractAdditions: VertragsErgaenzungsdokumentRest[];
    };
  };
  xplanDocumentContainer: {
    [key: string]: {
      loading: boolean;
      documents: DokumentRest[];
    };
  };
  planUploadError: boolean;
  planUploadErrorMessage: string;
  planUploadErrorTraceId: string;
  validationReports: ValidationReport[];
  documentTemplateCreation: {
    isInProgress: boolean;
    documentTemplateFile?: File;
    documentTemplateDescription?: string;
    documentTemplateCode?: string;
    documentType?: string;
  };
  documentTemplateAssignment: {
    codeVerfahrensschritt: CodeDescription;
    codeVerfahrenssteuerung: CodeDescription;
    codeVerfahrensteilschritt: CodeDescription;
  }[];
  generationModal: {
    open: boolean;
    steps: CodeDescription[];
  };
}

export const useDocumentsStore = defineStore("documents", {
  state: (): DocumentsStoreState => ({
    documentTemplates: [],
    documentContainer: {},
    xplanDocumentContainer: {},
    planUploadError: false,
    planUploadErrorMessage: "",
    planUploadErrorTraceId: "",
    validationReports: [],
    documentTemplateCreation: {
      isInProgress: false,
      documentTemplateFile: undefined,
      documentTemplateDescription: undefined,
      documentTemplateCode: undefined,
      documentType: undefined,
    },
    documentTemplateAssignment: [],
    generationModal: {
      open: false,
      steps: [],
    },
  }),
  actions: {
    /**
     * Loads all document templates and caches the data.
     * @param forceReload Indicator if a reload should be forced.
     */
    loadDocumentTemplates(forceReload = false): Promise<VorlageRest | unknown[]> {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        if (forceReload || this.documentTemplates.length < 1) {
          openAPIFactory
            .vorlageResourceApiFactory()
            .getVorlageUebersicht()
            .then((response) => {
              this.documentTemplates = response.data.sort((a, b) => {
                return a.beschreibung && b.beschreibung && a.beschreibung > b.beschreibung ? 1 : -1;
              });

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden der Mustervorlagen fehlgeschlagen!",
              });

              reject(error);
            });
        } else {
          resolve(this.documentTemplates);
        }
      });
    },
    /**
     * Deletes a specific document template.
     * @param code The document template code.
     */
    deleteDocumentTemplate(code: string): Promise<true> {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "documentTemplateDeletionInProgress",
        text: "Einen Moment bitte, die Mustervorlage wird gelöscht.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .vorlageResourceApiFactory()
          .deleteVorlage(code)
          .then(() => {
            this.documentTemplates = [];

            this.loadDocumentTemplates()
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Löschen der Vorlage ist fehlgeschlagen!",
            });

            reject(this.documentTemplates);
          })
          .finally(() => {
            // maybe load document templates from backend here
            // this acts as a refresh in case someone else added or deleted documents in the meantime

            appStore.hidePageLoadingIndicator("documentTemplateDeletionInProgress");
          });
      });
    },
    /**
     * Loads the contents of the document library of a given proceeding.
     * If no ID is given the ID is derived from the path, if the ID is provided encapsulated
     * within an object, a forced reload is done.
     * If xplan true returns xplanDocumentContainer else returns documentContainer.
     */
    loadProceedingDocuments(
      payload: {
        proceedingID?: string | { planID: string } | undefined;
        params?: {
          suchBegriff?: string;
          dateiNamen?: string[];
          bearbeiter?: string[];
          datum?: string[];
          codeDokumentstatus?: string[];
          versionen?: string[];
          codeVormerkung?: CodeVormerkung[];
          codeVerfahrensunterlagetyp?: CodeVerfahrensunterlagetyp[];
          fileID?: string[];
          klassifizierung?: GetDokumentUebersichtKlassifizierung[];
        };
        xplan?: boolean;
      } = {
        xplan: false,
      },
    ): Promise<DokumentRest[] | false> {
      const appStore = useAppStore();
      let pID: string | undefined;

      if (typeof payload.proceedingID === "string") {
        pID = payload.proceedingID;
      } else if (typeof payload.proceedingID === "object") {
        pID = payload.proceedingID.planID;
      } else {
        pID = getPlanID();
      }

      if (pID !== undefined) {
        const planID = pID as string;

        if (typeof this.documentContainer[planID] === "undefined") {
          this.documentContainer[planID] = {
            loading: true,
            documents: [],
            contracts: [],
            contractAdditions: [],
          };
        }

        if (typeof this.xplanDocumentContainer[planID] === "undefined") {
          this.xplanDocumentContainer[planID] = { loading: true, documents: [] };
        }

        this.documentContainer[planID].loading = true;
        this.xplanDocumentContainer[planID].loading = true;

        return new Promise((resolve, reject) => {
          const suchBegriff = payload.params?.suchBegriff;
          const dateiNamen = payload.params?.dateiNamen || [];
          const bearbeiter = payload.params?.bearbeiter || [];
          const datum = payload.params?.datum || [];
          const codeDokumentstatus = payload.params?.codeDokumentstatus || [];
          const versionen = payload.params?.versionen || [];
          const codeVormerkung = payload.params?.codeVormerkung || [];
          const codeVerfahrensunterlagetyp = payload.params?.codeVerfahrensunterlagetyp || [];
          const fileID = payload.params?.fileID || [];
          const klassifizierung = payload.params?.klassifizierung || [];

          openAPIFactory
            .dokumentResourceApiFactory()
            .getDokumentUebersicht(
              planID,
              suchBegriff,
              dateiNamen,
              bearbeiter,
              datum,
              codeDokumentstatus,
              versionen,
              codeVormerkung,
              codeVerfahrensunterlagetyp,
              fileID,
              klassifizierung,
            )
            .then((response) => {
              const documentContainer = { ...this.documentContainer };
              const xplanDocumentContainer = { ...this.xplanDocumentContainer };

              documentContainer[planID].documents = response.data.filter(
                (document) => document["@class"] === "DokumentRest",
              );

              documentContainer[planID].contracts = response.data.filter(
                (document) => document["@class"] === "VertragsdokumentRest",
              );

              documentContainer[planID].contractAdditions = response.data.filter(
                (document) => document["@class"] === "VertragsErgaenzungsdokumentRest",
              );

              xplanDocumentContainer[planID].documents = response.data.filter(
                (document) => document["@class"] === "XplanDokumentRest",
              );

              this.documentContainer = documentContainer;
              this.xplanDocumentContainer = xplanDocumentContainer;

              resolve(
                payload.xplan
                  ? this.xplanDocumentContainer[planID].documents
                  : this.documentContainer[planID].documents,
              );
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage:
                  "Fehler bei der Ermittlung des Inhaltes der Dokumentenbibliothek!",
              });

              reject(error);
            })
            .finally(() => {
              this.documentContainer[planID].loading = false;
              this.xplanDocumentContainer[planID].loading = false;
            });
        });
      }

      return Promise.resolve(false);
    },

    loadProceedingContractsOnly(proceedingId: string) {
      const appStore = useAppStore();

      if (proceedingId !== undefined) {
        if (typeof this.documentContainer[proceedingId] === "undefined") {
          this.documentContainer[proceedingId] = {
            loading: true,
            documents: [],
            contracts: [],
            contractAdditions: [],
          };
        }

        this.documentContainer[proceedingId].loading = true;

        return new Promise((resolve, reject) => {
          openAPIFactory
            .vertragsdokumentResourceApiFactory()
            .getVertragsdokumente(proceedingId)
            .then((response) => {
              this.documentContainer[proceedingId].contracts = response.data;
              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden der Verträge fehlgeschlagen!",
              });
              reject(error);
            })
            .finally(() => (this.documentContainer[proceedingId].loading = false));
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Marks a document as deleted.
     * @param payload
     * @param payload.fileID The identifier of the document.
     * @param payload.hard 'false' will result in a soft delete, placing the file in the trash bucket. 'true' results in a hard/final deletion.
     * @param payload.showPageLoadingIndicator Should a page loading indicator be displayed?
     * @param payload.reload Should the data be reloaded?
     * @param payload.handleErrors Should the errors be handled?
     */
    deleteDocument(payload: {
      fileID: string;
      hard: boolean;
      showPageLoadingIndicator: boolean;
      reload: boolean;
      handleErrors: boolean;
    }): Promise<boolean> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const appStore = useAppStore();

        if (payload.showPageLoadingIndicator) {
          appStore.showPageLoadingIndicator({
            id: "documentDeletionInProgress",
            text: "Einen Moment bitte, das Dokument wird gelöscht.",
          });
        }

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .deleteForceSingleDokumentOrSetDokumentStatusGeloescht(
              planID,
              payload.fileID,
              payload.hard.valueOf(),
            )
            .then(() => {
              if (payload.reload) {
                this.loadProceedingDocuments({ proceedingID: planID });
              }

              resolve(true);
            })
            .catch((error) => {
              if (payload.handleErrors) {
                appStore.showErrorModal({
                  response: error,
                  customErrorMessage: "Das Löschen des Dokumentes ist fehlgeschlagen!",
                });
              }

              reject(error);
            })
            .finally(() => {
              if (payload.showPageLoadingIndicator) {
                appStore.hidePageLoadingIndicator("documentDeletionInProgress");
              }
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Shares a document with a parallel procedure.
     *
     * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest - An array of documents to be shared with the parallel procedure.
     * @param {string} planID - The ID of the plan associated with the document.
     * @param {string} fileID - The ID of the file being shared.
     */
    shareDokument(
      verfahrenDokumentRest: Array<VerfahrenDokumentRest>,
      planID: string,
      fileID: string,
    ) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "shareDokument",
        text: "Einen Moment, das Dokument wird mit dem Parallelverfahren geteilt.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .dokumentResourceApiFactory()
          .shareDokument(verfahrenDokumentRest, planID, fileID)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage:
                "Das Dokument konnte nicht mit dem Parallelverfahren geteilt werden.",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("shareDokument");
          });
      });
    },
    /**
     * Updates the sharing level of a document in a parallel procedure.
     *
     * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest - An array of documents whose sharing levels are to be updated.
     * @param {string} planID - The ID of the plan associated with the document.
     * @param {string} fileID - The ID of the file whose sharing level is being updated.
     */
    updateSharedDokument(
      verfahrenDokumentRest: Array<VerfahrenDokumentRest>,
      planID: string,
      fileID: string,
    ) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "updateSharedDokument",
        text: "Einen Moment, die Freigabestufe für das Dokument wird aktualisiert.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .dokumentResourceApiFactory()
          .updateSharedDokument(verfahrenDokumentRest, planID, fileID)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage:
                "Die Freigabestufe für das Dokument konnte nicht aktualisiert werden.",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("updateSharedDokument");
          });
      });
    },
    /**
     * Removes the sharing status of a document in a parallel procedure.
     *
     * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest - An array of documents to be unshared.
     * @param {string} planID - The ID of the plan associated with the document.
     * @param {string} fileID - The ID of the file to be unshared.
     */
    unshareDokument(
      verfahrenDokumentRest: Array<VerfahrenDokumentRest>,
      planID: string,
      fileID: string,
    ) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "unshareDokument",
        text: "Einen Moment, die Freigabe für das Dokument wird entfernt.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .dokumentResourceApiFactory()
          .unshareDokument(verfahrenDokumentRest, planID, fileID)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Die Freigabe für das Dokument konnte nicht entfernt werden.",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("unshareDokument");
          });
      });
    },
    /**
     * Restores an array of documents from the deleted state.
     * @param files The array of fileIDs.
     */
    restoreDocuments(files: string[]): Promise<GetDokumentDetail200Response[] | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const appStore = useAppStore();

        appStore.showPageLoadingIndicator({
          id: "restoreDocuments",
          text:
            files.length > 1
              ? "Einen Moment bitte, die Dokumente werden wiederhergestellt."
              : "Einen Moment bitte, das Dokument wird wiederhergestellt.",
        });

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .deleteDokumentenStatus(planID, "1800", files)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage:
                  files.length > 1
                    ? "Das Wiederherstellen der Dokumente ist fehlgeschlagen!"
                    : "Das Wiederherstellen des Dokuments ist fehlgeschlagen!",
              });

              reject(error);
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("restoreDocuments");
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Deletes an array of documents.
     * @param files The array of fileIDs.
     */
    deleteDocuments(files: string[]): Promise<boolean[]> {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "deleteDocuments",
        text:
          files.length > 1
            ? "Einen Moment bitte, die Dokumente werden gelöscht."
            : "Einen Moment bitte, das Dokument wird gelöscht.",
      });

      return new Promise((resolve, reject) => {
        const promises: Promise<boolean>[] = [];

        for (const file of files) {
          promises.push(
            this.deleteDocument({
              fileID: file,
              hard: true,
              showPageLoadingIndicator: false,
              reload: false,
              handleErrors: false,
            }),
          );
        }

        const resolved: boolean[] = [];
        const rejected: string[] = [];

        Promise.allSettled(promises).then((results) => {
          for (const result of results) {
            if (result.status === "rejected") {
              rejected.push(result.reason);
            } else {
              resolved.push(result.value);
            }
          }

          if (resolved.length < files.length) {
            const errors: string[] = [];

            rejected.forEach((error, i) => {
              errors.push("(" + (i + 1) + ") " + error);
            });

            appStore.showErrorModal({
              response: {
                data: {
                  message: errors.join(" "),
                },
              },
              customErrorMessage:
                files.length > 1
                  ? "Das Löschen der Dokumente ist fehlgeschlagen!"
                  : "Das Löschen des Dokuments ist fehlgeschlagen!",
            });

            reject(errors.join(" "));
          } else {
            resolve(resolved);
          }

          appStore.hidePageLoadingIndicator("deleteDocuments");
        });
      });
    },
    /**
     * Updates a document status.
     * @param payload
     * @param payload.fileID The identifier of the document.
     * @param payload.showPageLoadingIndicator Should a page loading indicator be displayed?
     * @param payload.newStatus The new status of the document.
     * @param payload.callbackSuccess Potential callback function after successful file generation.
     * @param payload.callbackError Potential callback function in case of an error.
     */
    updateDocumentStatus(payload: {
      fileID: string;
      showPageLoadingIndicator: boolean;
      newStatus: string;
      callbackSuccess: (arg: DokumentRest) => void;
      callbackError: (arg: unknown) => void;
    }): Promise<DokumentRest | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const appStore = useAppStore();

        if (payload.showPageLoadingIndicator) {
          appStore.showPageLoadingIndicator({
            id: "documentStatusUpdateInProgress",
            text: "Einen Moment bitte, der Dokumentstatus wird geändert.",
          });
        }

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .setzeDokumentStatus(planID, payload.fileID, payload.newStatus)
            .then((response) => {
              if (payload.callbackSuccess) {
                payload.callbackSuccess(response.data);
              } else {
                this.loadProceedingDocuments({ proceedingID: planID });
              }

              resolve(response.data);
            })
            .catch((error) => {
              if (payload.callbackError && error) {
                payload.callbackError(error);
              } else {
                appStore.showErrorModal({
                  response: error,
                  customErrorMessage: "Das Ändern des Dokumentstatus ist fehlgeschlagen!",
                });
              }

              reject(error);
            })
            .finally(() => {
              if (payload.showPageLoadingIndicator) {
                appStore.hidePageLoadingIndicator("documentStatusUpdateInProgress");
              }
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Checks if a document about to be deleted is referenced somewhere in the proceeding.
     * @param fileID The ID of the document about to be deleted.
     */
    checkDocumentReferencePoints(fileID: string): Promise<FindActionItem200Response[] | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const appStore = useAppStore();

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .findActionItems(planID, fileID)
            .then(
              (response) => {
                resolve(response.data);
              },
              (error) => {
                appStore.showErrorModal({
                  response: error,
                  customErrorMessage: "Die Überprüfung der Dokumentreferenzen ist fehlgeschlagen!",
                });

                reject(error);
              },
            );
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Initiates a file generation from a given document template.
     * @param payload
     * @param payload.codeMusterdokument The code of the template document.
     * @param payload.codeVerfahrensteilschritt The code of the VTS the generated file should belong to.
     * @param payload.callbackSuccess Potential callback function after successful file generation.
     * @param payload.callbackError Potential callback function in case of an error.
     */
    generateDocumentFromTemplate(payload: {
      codeMusterdokument: string;
      codeVerfahrensteilschritt: string;
      callbackSuccess: (arg: DokumentRest) => void;
      callbackError: (arg: unknown) => void;
    }): Promise<DokumentRest | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const appStore = useAppStore();

        appStore.showPageLoadingIndicator({
          id: "generateDocumentFromTemplate",
          text: "Einen Moment bitte, das Dokument wird generiert.",
        });

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .erzeugeDokumentDateiAusVorlage(
              planID,
              payload.codeMusterdokument,
              payload.codeVerfahrensteilschritt,
            )
            .then((response) => {
              this.loadProceedingDocuments({ proceedingID: planID });

              if (payload.callbackSuccess) {
                payload.callbackSuccess(response.data);
              }

              resolve(response.data);
            })
            .catch((error) => {
              if (payload.callbackError && error) {
                payload.callbackError(error);
              } else {
                appStore.showErrorModal({
                  response: error,
                  customErrorMessage: "Das Dokument konnte nicht erzeugt werden!",
                });
              }

              reject(error);
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("generateDocumentFromTemplate");
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Sets a range of preliminary remarks on a specific file.
     */
    setDocumentPreliminaryRemarks(payload: {
      fileIDs: string[];
      codeVormerkung: CodeVormerkung;
    }): Promise<GetDokumentDetail200Response[] | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const appStore = useAppStore();

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .setDokumentVormerkung(payload, planID)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Fehler beim Setzen der Dokumentvormerkungen!",
              });

              reject(error);
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Removes a marking of a specific document.
     * @param payload
     * @param payload.fileID The file ID.
     * @param payload.markingCode The marking code to remove.
     */
    deleteDocumentMarking(payload: {
      fileID: string;
      markingCode: string;
    }): Promise<DokumentRest | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        const fundamentalStore = useFundamentalStore();
        const appStore = useAppStore();

        const vormerkungToBeRemoved = fundamentalStore
          .getCodelistByName("Vormerkungen")
          .find((vormerkung) => vormerkung.code === payload.markingCode);

        return new Promise((resolve, reject) => {
          openAPIFactory
            .dokumentResourceApiFactory()
            .loescheDokumentVormerkung(planID, payload.fileID, payload.markingCode)
            .then(
              (response) => {
                resolve(response.data);
              },
              (error) => {
                this.loadProceedingDocuments({ proceedingID: planID });

                appStore.showErrorModal({
                  response: error,
                  customErrorMessage:
                    "Die Löschung der Vormerkung" +
                    (vormerkungToBeRemoved?.name ? ' "' + vormerkungToBeRemoved.name + '"' : "") +
                    ' (Code: "' +
                    payload.markingCode +
                    '") ist fehlgeschlagen!',
                });

                reject(error);
              },
            );
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Uploads a XPlanGML-Archive.
     * @param file The file to upload.
     */
    uploadXPlanGMLArchive(file: File): Promise<boolean> {
      const appStore = useAppStore();
      const planID = getPlanID();

      if (planID !== undefined) {
        appStore.showPageLoadingIndicator({
          id: "uploadXPlanGMLArchive",
          text: "Einen Moment bitte, die Datei wird hochgeladen.",
        });

        return new Promise((resolve, reject) => {
          openAPIFactory
            .xplanManagerResourceApiFactory()
            .uploadXPlanGml(file, planID, true, false)
            .then(() => {
              this.resetPlanUploadError();

              this.loadProceedingDocuments({
                proceedingID: planID,
                params: {
                  codeVerfahrensunterlagetyp: [{ code: "0350" }],
                },
              });

              resolve(true);
            })
            .catch((error) => {
              const defaultErrorMsg =
                "Es ist ein unerwarteter Fehler beim Hochladen des Planwerks aufgetreten.";

              this.planUploadError = true;
              this.planUploadErrorMessage =
                error?.response?.data.message || error?.message || defaultErrorMsg;
              this.planUploadErrorTraceId = error?.response?.headers?.traceid;

              reject();
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("uploadXPlanGMLArchive");
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Validates an uploaded XPlanGML-Archive.
     * @param payload
     * @param payload.fileID The file ID.
     * @param payload.validationParameters query params.
     **/
    validateXPlanGML(payload: {
      fileID: string;
      validationParameters: {
        skipSemantisch: boolean;
        skipGeometrisch: boolean;
        skipFlaechenschluss: boolean;
        skipGeltungsbereich: boolean;
        skipLaufrichtung: boolean;
        name: string;
      };
    }): Promise<ValidationReportRest | false> {
      const planID = getPlanID();

      if (planID !== undefined) {
        return new Promise((resolve, reject) => {
          openAPIFactory
            .xplanManagerResourceApiFactory()
            .validateXPlanGml(
              planID,
              payload.fileID,
              payload.validationParameters.skipSemantisch,
              payload.validationParameters.skipGeometrisch,
              payload.validationParameters.skipFlaechenschluss,
              payload.validationParameters.skipGeltungsbereich,
              payload.validationParameters.skipLaufrichtung,
              payload.validationParameters.name,
            )
            .then((response) => {
              if (Object.keys(response.data).length) {
                this.setValidationReport({
                  fileID: payload.fileID,
                  validationResult: response.data.validationResult,
                  wmsUrl: response.data.wmsUrl,
                  mapUrl: response.data.mapUrl,
                  bbox: response.data.bbox,
                });

                this.loadProceedingDocuments({
                  proceedingID: planID,
                  params: {
                    codeVerfahrensunterlagetyp: [{ code: "0350" }],
                  },
                });
              } else {
                this.setValidationReport({
                  fileID: payload.fileID,
                  validationResult: {},
                  wmsUrl: "",
                  mapUrl: "",
                  bbox: undefined,
                });
              }

              resolve(response.data);
            })
            .catch((error) => {
              this.setValidationReport({
                fileID: payload.fileID,
                validationResult: {},
                wmsUrl: "",
                mapUrl: "",
                bbox: undefined,
              });

              reject(error);
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Publishes a validated XPlanGML-Archive.
     * @param payload
     * @param payload.fileID The file ID.
     * @param payload.publishParameters query params.
     **/
    publishXPlanGML(payload: {
      fileID: string;
      publishParameters: {
        skipSemantisch: boolean;
        skipFlaechenschluss: boolean;
        skipGeltungsbereich: boolean;
        skipLaufrichtung: boolean;
      };
    }): Promise<false | DokumentRest> {
      const planID = getPlanID();

      if (planID !== undefined) {
        return new Promise((resolve, reject) => {
          openAPIFactory
            .xplanManagerResourceApiFactory()
            .publishXPlanGml(
              planID,
              payload.fileID,
              payload.publishParameters.skipSemantisch,
              payload.publishParameters.skipFlaechenschluss,
              payload.publishParameters.skipGeltungsbereich,
              payload.publishParameters.skipLaufrichtung,
            )
            .then((response) => {
              this.loadProceedingDocuments({
                proceedingID: planID,
                params: {
                  codeVerfahrensunterlagetyp: [{ code: "0350" }],
                },
              });

              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Deletes a published XplanGML.
     * @param {string} planId
     * planID passed in case used in admin
     */
    deleteXPlanGML(planId: string): Promise<XplanManagerStatusMessageRest | false> {
      const appStore = useAppStore();
      const planID = planId ? planId : getPlanID();

      if (planID !== undefined) {
        return new Promise((resolve, reject) => {
          openAPIFactory
            .xplanManagerResourceApiFactory()
            .deleteXplanGml(planID)
            .then((response) => {
              this.loadProceedingDocuments({
                proceedingID: planID,
                params: {
                  codeVerfahrensunterlagetyp: [{ code: "0350" }],
                },
              });

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Das Löschen des XplanGML ist fehlgeschlagen!",
              });

              reject(error);
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Resets uploadErrorMessage and error state for xplan upload.
     */
    resetPlanUploadError() {
      this.planUploadError = false;
      this.planUploadErrorMessage = "";
      this.planUploadErrorTraceId = "";
    },
    /**
     * Sets report after validating XPlanGml archive.
     * @param payload Validation report object.
     *
     */
    setValidationReport(payload: ValidationReport) {
      const validationReports = [...this.validationReports];
      const index = validationReports.findIndex((report) => report.fileID === payload.fileID);

      if (index !== -1) {
        validationReports[index] = {
          fileID: payload.fileID,
          validationResult: payload.validationResult,
          wmsUrl: payload.wmsUrl,
          mapUrl: payload.mapUrl,
          bbox: payload.bbox,
        };
      } else {
        validationReports.push({
          fileID: payload.fileID,
          validationResult: payload.validationResult,
          wmsUrl: payload.wmsUrl,
          mapUrl: payload.mapUrl,
          bbox: payload.bbox,
        });
      }

      this.validationReports = validationReports;
    },
    /**
     * Sets inputs from modal 1 of document template creation (for the zurück functionality).
     */
    setDocumentTemplateModal1Data(payload: {
      isInProgress: boolean;
      documentTemplateFile: File;
      documentTemplateDescription: string;
      documentTemplateCode: string;
      documentType: string;
    }) {
      this.documentTemplateCreation = {
        ...this.documentTemplateCreation,
        isInProgress: payload.isInProgress,
        documentTemplateFile: payload.documentTemplateFile,
        documentTemplateDescription: payload.documentTemplateDescription,
        documentTemplateCode: payload.documentTemplateCode,
        documentType: payload.documentType,
      };
    },
    /**
     * Resets document template creation data
     */
    resetDocumentTemplateData() {
      this.documentTemplateCreation = {
        isInProgress: false,
        documentTemplateFile: undefined,
        documentTemplateDescription: undefined,
        documentTemplateCode: undefined,
        documentType: undefined,
      };

      this.documentTemplateAssignment = [];
    },
    openDocumentGenerationModal(steps = []) {
      this.generationModal.open = true;
      this.generationModal.steps = steps;
    },
    closeDocumentGenerationModal() {
      this.generationModal.open = false;
      this.generationModal.steps = [];
    },
    /*Update a particular Document data by ID */
    setDocumentData(documentData: DokumentRest, planID: string) {
      const itemIndex = this.documentContainer[planID].documents.findIndex(
        (documentItem) => documentItem.fileID === documentData.fileID,
      );

      if (itemIndex !== -1) {
        this.documentContainer[planID].documents[itemIndex] = {
          ...documentData,
        };
      }
    },
  },
  getters: {
    /**
     * Get all documents of a specific proceeding ID
     */
    getAllDocumentsByProceedingID(): (proceedingID: string) => DokumentRest[] {
      return (proceedingID) => {
        if (
          typeof this.documentContainer[proceedingID] !== "undefined" &&
          Array.isArray(this.documentContainer[proceedingID].documents) &&
          Array.isArray(this.documentContainer[proceedingID].contracts) &&
          Array.isArray(this.documentContainer[proceedingID].contractAdditions)
        ) {
          return [
            ...this.documentContainer[proceedingID].documents,
            ...this.documentContainer[proceedingID].contracts,
            ...this.documentContainer[proceedingID].contractAdditions,
          ] as DokumentRest[];
        }

        return [];
      };
    },
    /**
     * Get  document by name and proceedingID
     */
    getDocumentByFilename(): (filename: string, proceedingID: string) => DokumentRest | undefined {
      return (filename, proceedingID) => {
        const documents = this.getAllDocumentsByProceedingID(proceedingID);

        return documents.find((document) => document.filename === filename);
      };
    },
    /**
     * Get only documents of a specific proceeding ID
     */
    getDocumentsByProceedingID(): (proceedingID: string) => DokumentRest[] {
      return (proceedingID) => {
        if (
          typeof this.documentContainer[proceedingID] !== "undefined" &&
          Array.isArray(this.documentContainer[proceedingID].documents)
        ) {
          return this.documentContainer[proceedingID].documents as DokumentRest[];
        }

        return [];
      };
    },

    /**
     * Get the contracts of a specific proceeding.
     */
    getContractsByProceedingID(): (proceedingID: string) => VertragsdokumentRest[] {
      return (proceedingID) => {
        if (
          typeof this.documentContainer[proceedingID] !== "undefined" &&
          Array.isArray(this.documentContainer[proceedingID].contracts)
        ) {
          return this.documentContainer[proceedingID].contracts as VertragsdokumentRest[];
        }

        return [];
      };
    },

    /**
     * Get the contracts and additions of a specific proceeding.
     */
    getContractsAndAdditionsByProceedingID(): (proceedingID: string) => VertragsdokumentRest[] {
      return (proceedingID) => {
        if (
          typeof this.documentContainer[proceedingID] !== "undefined" &&
          Array.isArray(this.documentContainer[proceedingID].contracts) &&
          Array.isArray(this.documentContainer[proceedingID].contractAdditions)
        ) {
          return getSortedContractsAndAdditions(
            this.documentContainer[proceedingID].contracts,
            this.documentContainer[proceedingID].contractAdditions,
          ) as VertragsdokumentRest[];
        }

        return [];
      };
    },

    /**
     * Get the xplanDocuments of a specific planID.
     */
    getXplanDocumentsByPlanID(): (planID: string) => DokumentRest[] {
      return (planID) => {
        if (
          typeof this.xplanDocumentContainer[planID] !== "undefined" &&
          Array.isArray(this.xplanDocumentContainer[planID].documents)
        ) {
          return this.xplanDocumentContainer[planID].documents as DokumentRest[];
        }

        return [];
      };
    },
    /**
     * Get the validation results of a specific file.
     */
    getValidationReportsByFileID(): (
      fileID: string,
    ) => ValidationReportValidationResult | undefined {
      return (fileID) => {
        const foundItem: ValidationReport | undefined = this.validationReports.find(
          (item) => item.fileID === fileID,
        );

        return foundItem && foundItem.validationResult ? foundItem.validationResult : undefined;
      };
    },
    /**
     * Get the WmsUrl of a specific file.
     */
    getWmsUrlByFileID(): (fileID: string) => string | undefined {
      return (fileID) => {
        const foundItem: ValidationReport | undefined = this.validationReports.find(
          (item) => item.fileID === fileID,
        );

        return foundItem && foundItem.wmsUrl ? foundItem.wmsUrl : undefined;
      };
    },
    /**
     * Get the mapUrl of a specific file.
     */
    getMapUrlByFileID(): (fileID: string) => string | undefined {
      return (fileID) => {
        const foundItem: ValidationReport | undefined = this.validationReports.find(
          (item) => item.fileID === fileID,
        );

        return foundItem && foundItem.mapUrl ? foundItem.mapUrl : undefined;
      };
    },
    /**
     * Get the bbox of a specific file.
     */
    getBboxByFileID(): (fileID: string) => PlanInfoBbox | undefined {
      return (fileID) => {
        const foundItem: ValidationReport | undefined = this.validationReports.find(
          (item) => item.fileID === fileID,
        );

        return foundItem && foundItem.bbox ? foundItem.bbox : undefined;
      };
    },
  },
});
