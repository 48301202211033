import {
  GetKonfigurationenCodelistenResolver,
  GetKonfigurationenRechtszitateResolver,
  GetKonfigurationenVerfahrenResolver,
  GetKonfigurationenWhitelistSerieResolver,
  GetNutzerVerfahrenResolver,
  GetVorlagenUebersichtResolver,
} from "@/router/services.js";

export const adminRoutes = {
  path: "/admin",
  component: () => import("@/views/VAdmin.vue"),
  name: "AdminPage",
  props: true,
  children: [
    {
      path: "",
      name: "AdminPageRedirect",
      redirect: { name: "Systemparameter" },
      meta: {
        title: ["Fachliche Leitstelle Administration", "Systemparameter"],
        label: "",
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
        },
      },
    },
    {
      path: "systemparameter",
      component: () => import("@/views/VAdminSystemParameters.vue"),
      name: "Systemparameter",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Systemparameter"],
        label: "Systemparameter",
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
        },
      },
    },
    {
      path: "konfiguration",
      component: () => import("@/views/VAdminConfiguration.vue"),
      name: "ProceedingConfiguration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Konfiguration"],
        label: "Konfiguration",
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/whitelistserie": GetKonfigurationenWhitelistSerieResolver,
        },
      },
    },
    {
      path: "codelisten",
      component: () => import("@/views/VAdminCodeLists.vue"),
      name: "CodelistenAdministration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Codelisten"],
        label: "Codelisten",
        resolve: {},
      },
    },
    {
      path: "verfahrensverwaltung",
      component: () => import("@/views/VAdminProceedings.vue"),
      name: "ProceedingAdministration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Verfahrensverwaltung"],
        label: "Verfahrensverwaltung",
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
        },
      },
    },
    {
      path: "planwerksverwaltung",
      component: () => import("@/views/VAdminPlanwerke.vue"),
      name: "PlanwerkAdministration",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Planwerksverwaltung"],
        label: "Planwerksverwaltung",
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
        },
      },
    },
    {
      path: "transfermessages",
      component: () => import("@/views/VAdminTransferMessages.vue"),
      name: "TransferMessages",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Transfer Messages"],
        label: "Transfer Messages",
        resolve: {},
      },
    },
    {
      path: "mustervorlagen",
      component: () => import("@/views/VAdminDocumentTemplates.vue"),
      name: "DocumentTemplates",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Mustervorlagen"],
        label: "Mustervorlagen",
        requiredPermissions: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/vorlagen/uebersicht": GetVorlagenUebersichtResolver,
        },
      },
    },
    {
      path: "rechtszitate",
      component: () => import("@/views/VAdminRechtszitate.vue"),
      name: "Rechtszitate",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Rechtszitate"],
        label: "Rechtszitate",
        requiredPermissions: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/rechtszitate": GetKonfigurationenRechtszitateResolver,
        },
      },
    },
    {
      path: "protokolle",
      component: () => import("@/views/VAdminProtocols.vue"),
      name: "Protocols",
      props: true,
      meta: {
        title: ["Fachliche Leitstelle Administration", "Protokolle"],
        label: "Protokolle",
        resolve: {},
      },
    },
  ],
};
