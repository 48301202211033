/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.139.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { TerminDetailRest } from '../models';
// @ts-ignore
import { ZeitplanungEditRest } from '../models';
// @ts-ignore
import { ZeitplanungRest } from '../models';
/**
 * TerminResourceApi - axios parameter creator
 * @export
 */
export const TerminResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} terminID 
         * @param {boolean} [nachfolgendeNeuBerechnen] 
         * @param {boolean} [geplanteNeuBerechnen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermin: async (planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteTermin', 'planID', planID)
            // verify required parameter 'terminID' is not null or undefined
            assertParamExists('deleteTermin', 'terminID', terminID)
            const localVarPath = `/verfahren/{planID}/zeitplanung/{terminID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"terminID"}}`, encodeURIComponent(String(terminID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nachfolgendeNeuBerechnen !== undefined) {
                localVarQueryParameter['nachfolgendeNeuBerechnen'] = nachfolgendeNeuBerechnen;
            }

            if (geplanteNeuBerechnen !== undefined) {
                localVarQueryParameter['geplanteNeuBerechnen'] = geplanteNeuBerechnen;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert alle Termine zu einem Verfahren.
         * @param {string} planID planID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermine: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getTermine', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/termine`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.
         * @param {string} planID planID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZeitplanung: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getZeitplanung', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/zeitplanung`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Initiale Prognose der Termine. Diese Prognose wird abgespeichert und ist nicht mehr änderbar.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Initiale Prognose der Termine.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} prognoseStartdatum Startdatum vom VTS Grobabstimmung.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeZeitplanung: async (planID: string, prognoseStartdatum: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('initializeZeitplanung', 'planID', planID)
            // verify required parameter 'prognoseStartdatum' is not null or undefined
            assertParamExists('initializeZeitplanung', 'prognoseStartdatum', prognoseStartdatum)
            const localVarPath = `/verfahren/{planID}/zeitplanung/init`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (prognoseStartdatum !== undefined) {
                localVarQueryParameter['prognoseStartdatum'] = (prognoseStartdatum as any instanceof Date) ?
                    (prognoseStartdatum as any).toISOString() :
                    prognoseStartdatum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.
         * @param {string} planID planID des Verfahrens
         * @param {string} terminID terminID des geaenderten Termins
         * @param {string} zeitraumBeginn Zeitraum-Beginn
         * @param {string} [zeitraumEnde] Zeitraum-Ende
         * @param {boolean} [nachfolgendeNeuBerechnen] Flag, ob nachfolgende Termine auch neu berechnet werden sollen
         * @param {boolean} [geplanteNeuBerechnen] Flag, ob geplante Termine auch neu berechnet werden sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVerzoegert: async (planID: string, terminID: string, zeitraumBeginn: string, zeitraumEnde?: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('isVerzoegert', 'planID', planID)
            // verify required parameter 'terminID' is not null or undefined
            assertParamExists('isVerzoegert', 'terminID', terminID)
            // verify required parameter 'zeitraumBeginn' is not null or undefined
            assertParamExists('isVerzoegert', 'zeitraumBeginn', zeitraumBeginn)
            const localVarPath = `/verfahren/{planID}/zeitplanung/{terminID}/istverzoegert`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"terminID"}}`, encodeURIComponent(String(terminID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (zeitraumBeginn !== undefined) {
                localVarQueryParameter['zeitraumBeginn'] = (zeitraumBeginn as any instanceof Date) ?
                    (zeitraumBeginn as any).toISOString() :
                    zeitraumBeginn;
            }

            if (zeitraumEnde !== undefined) {
                localVarQueryParameter['zeitraumEnde'] = (zeitraumEnde as any instanceof Date) ?
                    (zeitraumEnde as any).toISOString() :
                    zeitraumEnde;
            }

            if (nachfolgendeNeuBerechnen !== undefined) {
                localVarQueryParameter['nachfolgendeNeuBerechnen'] = nachfolgendeNeuBerechnen;
            }

            if (geplanteNeuBerechnen !== undefined) {
                localVarQueryParameter['geplanteNeuBerechnen'] = geplanteNeuBerechnen;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Änderung eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisieren der Terminplanung durch Änderung eines Termins.
         * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} terminID terminID des {@link Termin Termin}s
         * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
         * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZeitplanungExisting: async (zeitplanungEditRest: ZeitplanungEditRest, planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zeitplanungEditRest' is not null or undefined
            assertParamExists('updateZeitplanungExisting', 'zeitplanungEditRest', zeitplanungEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateZeitplanungExisting', 'planID', planID)
            // verify required parameter 'terminID' is not null or undefined
            assertParamExists('updateZeitplanungExisting', 'terminID', terminID)
            const localVarPath = `/verfahren/{planID}/zeitplanung/{terminID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"terminID"}}`, encodeURIComponent(String(terminID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nachfolgendeNeuBerechnen !== undefined) {
                localVarQueryParameter['nachfolgendeNeuBerechnen'] = nachfolgendeNeuBerechnen;
            }

            if (geplanteNeuBerechnen !== undefined) {
                localVarQueryParameter['geplanteNeuBerechnen'] = geplanteNeuBerechnen;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zeitplanungEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Hinzugügen eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisieren der Terminplanung durch Hinzugügen eines Termins.
         * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
         * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZeitplanungNew: async (zeitplanungEditRest: ZeitplanungEditRest, planID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zeitplanungEditRest' is not null or undefined
            assertParamExists('updateZeitplanungNew', 'zeitplanungEditRest', zeitplanungEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateZeitplanungNew', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/zeitplanung/neu`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nachfolgendeNeuBerechnen !== undefined) {
                localVarQueryParameter['nachfolgendeNeuBerechnen'] = nachfolgendeNeuBerechnen;
            }

            if (geplanteNeuBerechnen !== undefined) {
                localVarQueryParameter['geplanteNeuBerechnen'] = geplanteNeuBerechnen;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(zeitplanungEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminResourceApi - functional programming interface
 * @export
 */
export const TerminResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} terminID 
         * @param {boolean} [nachfolgendeNeuBerechnen] 
         * @param {boolean} [geplanteNeuBerechnen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTermin(planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeitplanungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTermin(planID, terminID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.deleteTermin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert alle Termine zu einem Verfahren.
         * @param {string} planID planID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermine(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TerminDetailRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermine(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.getTermine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.
         * @param {string} planID planID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZeitplanung(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeitplanungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZeitplanung(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.getZeitplanung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Initiale Prognose der Termine. Diese Prognose wird abgespeichert und ist nicht mehr änderbar.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Initiale Prognose der Termine.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} prognoseStartdatum Startdatum vom VTS Grobabstimmung.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeZeitplanung(planID: string, prognoseStartdatum: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeitplanungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeZeitplanung(planID, prognoseStartdatum, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.initializeZeitplanung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.
         * @param {string} planID planID des Verfahrens
         * @param {string} terminID terminID des geaenderten Termins
         * @param {string} zeitraumBeginn Zeitraum-Beginn
         * @param {string} [zeitraumEnde] Zeitraum-Ende
         * @param {boolean} [nachfolgendeNeuBerechnen] Flag, ob nachfolgende Termine auch neu berechnet werden sollen
         * @param {boolean} [geplanteNeuBerechnen] Flag, ob geplante Termine auch neu berechnet werden sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isVerzoegert(planID: string, terminID: string, zeitraumBeginn: string, zeitraumEnde?: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isVerzoegert(planID, terminID, zeitraumBeginn, zeitraumEnde, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.isVerzoegert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Änderung eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisieren der Terminplanung durch Änderung eines Termins.
         * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} terminID terminID des {@link Termin Termin}s
         * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
         * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateZeitplanungExisting(zeitplanungEditRest: ZeitplanungEditRest, planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeitplanungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateZeitplanungExisting(zeitplanungEditRest, planID, terminID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.updateZeitplanungExisting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Hinzugügen eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisieren der Terminplanung durch Hinzugügen eines Termins.
         * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
         * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateZeitplanungNew(zeitplanungEditRest: ZeitplanungEditRest, planID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZeitplanungRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateZeitplanungNew(zeitplanungEditRest, planID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TerminResourceApi.updateZeitplanungNew']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TerminResourceApi - factory interface
 * @export
 */
export const TerminResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} terminID 
         * @param {boolean} [nachfolgendeNeuBerechnen] 
         * @param {boolean} [geplanteNeuBerechnen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTermin(planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: any): AxiosPromise<ZeitplanungRest> {
            return localVarFp.deleteTermin(planID, terminID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert alle Termine zu einem Verfahren.
         * @param {string} planID planID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermine(planID: string, options?: any): AxiosPromise<Array<TerminDetailRest>> {
            return localVarFp.getTermine(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.
         * @param {string} planID planID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZeitplanung(planID: string, options?: any): AxiosPromise<ZeitplanungRest> {
            return localVarFp.getZeitplanung(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Initiale Prognose der Termine. Diese Prognose wird abgespeichert und ist nicht mehr änderbar.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Initiale Prognose der Termine.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} prognoseStartdatum Startdatum vom VTS Grobabstimmung.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeZeitplanung(planID: string, prognoseStartdatum: string, options?: any): AxiosPromise<ZeitplanungRest> {
            return localVarFp.initializeZeitplanung(planID, prognoseStartdatum, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.
         * @param {string} planID planID des Verfahrens
         * @param {string} terminID terminID des geaenderten Termins
         * @param {string} zeitraumBeginn Zeitraum-Beginn
         * @param {string} [zeitraumEnde] Zeitraum-Ende
         * @param {boolean} [nachfolgendeNeuBerechnen] Flag, ob nachfolgende Termine auch neu berechnet werden sollen
         * @param {boolean} [geplanteNeuBerechnen] Flag, ob geplante Termine auch neu berechnet werden sollen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isVerzoegert(planID: string, terminID: string, zeitraumBeginn: string, zeitraumEnde?: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.isVerzoegert(planID, terminID, zeitraumBeginn, zeitraumEnde, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Änderung eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisieren der Terminplanung durch Änderung eines Termins.
         * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} terminID terminID des {@link Termin Termin}s
         * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
         * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZeitplanungExisting(zeitplanungEditRest: ZeitplanungEditRest, planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: any): AxiosPromise<ZeitplanungRest> {
            return localVarFp.updateZeitplanungExisting(zeitplanungEditRest, planID, terminID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Hinzugügen eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisieren der Terminplanung durch Hinzugügen eines Termins.
         * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
         * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZeitplanungNew(zeitplanungEditRest: ZeitplanungEditRest, planID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: any): AxiosPromise<ZeitplanungRest> {
            return localVarFp.updateZeitplanungNew(zeitplanungEditRest, planID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TerminResourceApi - object-oriented interface
 * @export
 * @class TerminResourceApi
 * @extends {BaseAPI}
 */
export class TerminResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} terminID 
     * @param {boolean} [nachfolgendeNeuBerechnen] 
     * @param {boolean} [geplanteNeuBerechnen] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public deleteTermin(planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).deleteTermin(planID, terminID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert alle Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
     * @summary Liefert alle Termine zu einem Verfahren.
     * @param {string} planID planID des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public getTermine(planID: string, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).getTermine(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
     * @summary Liefert die Zeitplanung inklusive aller terminplanungsrelevanten Termine zu einem Verfahren.
     * @param {string} planID planID des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public getZeitplanung(planID: string, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).getZeitplanung(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Initiale Prognose der Termine. Diese Prognose wird abgespeichert und ist nicht mehr änderbar.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @summary Initiale Prognose der Termine.
     * @param {string} planID planID des {@link Verfahren Verfahren}s
     * @param {string} prognoseStartdatum Startdatum vom VTS Grobabstimmung.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public initializeZeitplanung(planID: string, prognoseStartdatum: string, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).initializeZeitplanung(planID, prognoseStartdatum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
     * @summary Prueft einen Termin und optional nachfolgende Termine auf eine Verzoegerung bezueglich des Monitorings.
     * @param {string} planID planID des Verfahrens
     * @param {string} terminID terminID des geaenderten Termins
     * @param {string} zeitraumBeginn Zeitraum-Beginn
     * @param {string} [zeitraumEnde] Zeitraum-Ende
     * @param {boolean} [nachfolgendeNeuBerechnen] Flag, ob nachfolgende Termine auch neu berechnet werden sollen
     * @param {boolean} [geplanteNeuBerechnen] Flag, ob geplante Termine auch neu berechnet werden sollen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public isVerzoegert(planID: string, terminID: string, zeitraumBeginn: string, zeitraumEnde?: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).isVerzoegert(planID, terminID, zeitraumBeginn, zeitraumEnde, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Änderung eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @summary Aktualisieren der Terminplanung durch Änderung eines Termins.
     * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
     * @param {string} planID planID des {@link Verfahren Verfahren}s
     * @param {string} terminID terminID des {@link Termin Termin}s
     * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
     * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public updateZeitplanungExisting(zeitplanungEditRest: ZeitplanungEditRest, planID: string, terminID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).updateZeitplanungExisting(zeitplanungEditRest, planID, terminID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisieren der Terminplanung durch Hinzugügen eines Termins.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @summary Aktualisieren der Terminplanung durch Hinzugügen eines Termins.
     * @param {ZeitplanungEditRest} zeitplanungEditRest zeitplanungsrelevanten Informationen z.B. Liste aller zeitplanungsrelevanten Termine
     * @param {string} planID planID des {@link Verfahren Verfahren}s
     * @param {boolean} [nachfolgendeNeuBerechnen] nachfolgende Termine automatisiert nach Aktualisierung eines Termines neu zu berechnen
     * @param {boolean} [geplanteNeuBerechnen] nachfolgende geplante Termine automatisiert nach Aktualisierung eines Termines neu zu                                  berechnen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminResourceApi
     */
    public updateZeitplanungNew(zeitplanungEditRest: ZeitplanungEditRest, planID: string, nachfolgendeNeuBerechnen?: boolean, geplanteNeuBerechnen?: boolean, options?: RawAxiosRequestConfig) {
        return TerminResourceApiFp(this.configuration).updateZeitplanungNew(zeitplanungEditRest, planID, nachfolgendeNeuBerechnen, geplanteNeuBerechnen, options).then((request) => request(this.axios, this.basePath));
    }
}

