/* eslint-disable vue/max-len */
// stylelint-disable-next-line scss/at-import-partial-extension
import "@/styles/applications/dpc/index.scss";

import { createPinia } from "pinia";
import { createApp } from "vue";

import App from "@/App.vue";
import HelperFunctions from "@/plugins/helper-functions.js";
import router from "@/router";
import { GetNutzerResolver, setPageTitle, userHasPermissions } from "@/router/services.js";
import { logAppInfos } from "@/services/app.js";
import { useAppStore } from "@/stores/app.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useProceedingsStore } from "@/stores/proceedings.ts";
/* eslint-enable vue/max-len */

const app = createApp(App);
const pinia = createPinia();

const isPublicPage = (to) => {
  return to.matched.some((record) => record.meta.isPublicPage);
};

router.beforeEach(async (to) => {
  setPageTitle(to);

  if (!isPublicPage(to)) {
    const appStore = useAppStore();
    const authStore = useAuthStore();
    let hasUserError = false;

    localStorage.setItem("isLogoutVisible", JSON.stringify(false));

    // Authenticates the user against Keycloak
    await authStore.init();

    if (appStore.resolved["/nutzer"] !== true) {
      // Resolve user data once
      await GetNutzerResolver().catch(() => {
        hasUserError = true;
      });

      // Log appInfos once
      await logAppInfos();
    }

    // Redirect the user to the access denied page
    if (hasUserError || !hasPermissions(to)) return { name: "AppAccessDenied" };
  }
});

router.beforeResolve(async (to, from) => {
  // check for exception for same VTS
  const isTaskRouteUpdate = !!(to.meta.isTasksView && from.meta.isTasksView);
  const isSameVTSRoute = !!(
    isTaskRouteUpdate &&
    to.params.planID === from.params.planID &&
    to.params.vtsID === from.params.vtsID
  );

  if (!isSameVTSRoute) {
    // fetch all page data before the navigation is resolved
    await resolveServices(to);
  }
});

/**
 * Fetch all page data defined in meta fields per route
 * @param to - target route location
 * @returns {Promise<void>} - return promise
 */
const resolveServices = async (to) => {
  const appStore = useAppStore();

  appStore.showPageLoadingIndicator({
    id: "resolveServices",
    text: "Einen Moment bitte, die Daten werden geladen.",
  });

  // from router meta fields get services to resolve
  const services = to.meta.resolve ?? {};

  // check subscribtion for all detailpages
  if (to.matched.find((match) => match.name === "DetailPage")) {
    await updateSubscription(to);
  }

  // await all data fetching services
  // resolve promise if all promises were successful
  await Promise.all(Object.entries(services).map((service) => service[1](to.params)))
    .then(() => {
      // console.log(res);
    })
    .catch(() => {
      // errors are handled in services
    })
    .finally(() => {
      appStore.hidePageLoadingIndicator("resolveServices");
    });
};

const hasPermissions = (to) => {
  const requiredPermissions = to.meta.requiredPermissions ?? [];

  if (Array.isArray(requiredPermissions) && requiredPermissions.length) {
    return userHasPermissions(requiredPermissions);
  } else if (typeof requiredPermissions === "object") {
    for (const value of Object.values(requiredPermissions)) {
      if (!userHasPermissions(value)) return false;
    }
  }
  return true;
};

const updateSubscription = async (to) => {
  const proceedingsStore = useProceedingsStore();
  const { planID } = to.params;

  // set proceeding always as subscribed and show dialog if proceeding was not subscribed before
  return proceedingsStore.addSubscribedProceedings(planID);
};

// Custom directive providing a "v-click-outside" option on elements.
app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

// Custom directive providing a "v-tab-outside" option on elements.
app.directive("tab-outside", {
  mounted(el, binding) {
    el.tabOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("keydown", el.tabOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("keydown", el.tabOutsideEvent);
  },
});

const appMount = async (element) => {
  app.use(pinia);
  app.use(router);
  app.use(HelperFunctions);
  app.provide("uniqueIdProvider", app.config.globalProperties.$getUniqueId);

  app.mount(element);
};

const initializeApp = async () => {
  await appMount("#root");
};

initializeApp()
  .then(() => console.log("DiPlanung Cockpit Basis successfully initialized!"))
  .catch(() => console.error(`DiPlanung Cockpit Basis ${app.version} failed to initialize`));
