import {
  GetKonfigurationenBlacklistResolver,
  GetKonfigurationenCodelistenResolver,
  GetKonfigurationenVerfahrenResolver,
  GetNutzerVerfahrenResolver,
  GetVerfahrenDetailResolver,
  GetVerfahrenDokumentePlanwerkeResolver,
  GetVerfahrenDokumenteResolver,
  GetVerfahrenVerfahrenteilschrittResolver,
  GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
  GetVerfahrenVertragsdokumenteResolver,
  GetVerfahrenZeitplanungResolver,
} from "@/router/services.js";

export const detailRoutes = {
  path: "/details/:planID/",
  component: () => import("@/views/VDetail.vue"),
  name: "DetailPage",
  props: true,
  meta: {
    resolve: {
      "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
      "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
      "/verfahren/{planID}": GetVerfahrenDetailResolver,
      "/verfahren/{planID}/verfahrensteilschritteuebersicht":
        GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
    },
  },
  children: [
    {
      path: "stammdaten",
      component: () => import("@/views/VDetailData.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Stammdaten"],
        label: "Stammdaten",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/blacklist": GetKonfigurationenBlacklistResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
          "/verfahren/{planID}/vertragsdokument": GetVerfahrenVertragsdokumenteResolver,
        },
      },
    },
    {
      path: "zeitplanung",
      component: () => import("@/views/VDetailSchedule.vue"),
      name: "zeitplanung",
      props: true,
      meta: {
        title: ["{proceedingName}", "Zeitplanung"],
        label: "Zeitplanung",
        requiredPermissions: {
          0: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
          1: ["EIGENE_VERFAHREN_READ", "EIGENE_VERFAHREN_WRITE"],
        },
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/blacklist": GetKonfigurationenBlacklistResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
          "/verfahren/{planID}/schedule": GetVerfahrenZeitplanungResolver,
        },
      },
    },
    {
      path: ":vtsID/:roundID/:uvtsID?/:taskID?",
      component: () => import("@/views/VDetailTasks.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Aufgaben"],
        label: "Aufgaben",
        isTasksView: true,
        requiredPermissions: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/blacklist": GetKonfigurationenBlacklistResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
          "/verfahren/{planID}/verfahrensteilschritte/{code}":
            GetVerfahrenVerfahrenteilschrittResolver,
          "/verfahren/{planID}/schedule": GetVerfahrenZeitplanungResolver,
          "/verfahren/{planID}/dokumente": GetVerfahrenDokumenteResolver,
        },
      },
    },
    {
      path: "planwerke",
      component: () => import("@/views/VDetailPlanwerke.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Planwerke"],
        label: "Planwerke",
        requiredPermissions: ["ADMIN", "PLANWERKE_READ", "EIGENE_PLANWERKE_READ"],
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
          " /verfahren/{planID}/dokumente?codeVerfahrensunterlagetyp=0350":
            GetVerfahrenDokumentePlanwerkeResolver,
        },
      },
    },
    {
      path: "dokumente",
      component: () => import("@/views/VDetailDocuments.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Dokumente"],
        label: "Dokumente",
        requiredPermissions: ["ADMIN", "DOKUMENT_READ", "EIGENE_DOKUMENT_READ"],
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
        },
      },
    },

    {
      path: "stellungnahmen/:vsID?/:vtsID?/:roundID?/",
      component: () => import("@/views/VDetailStatements.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Stellungnahmen"],
        label: "Stellungnahmen",
        isStatementsView: true,
        requiredPermissions: {
          0: ["VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
          1: ["EIGENE_STELLUNGNAHME_READ", "EIGENE_STELLUNGNAHME_WRITE"],
        },
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/blacklist": GetKonfigurationenBlacklistResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
        },
      },
    },

    {
      path: "protokolle",
      component: () => import("@/views/VDetailProtocols.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Protokolle"],
        label: "Protokolle",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/konfigurationen/blacklist": GetKonfigurationenBlacklistResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
        },
      },
    },
    {
      path: "einstellungen",
      component: () => import("@/views/VDetailSettings.vue"),
      props: true,
      meta: {
        title: ["{proceedingName}", "Einstellungen"],
        label: "Einstellungen",
        requiredPermissions: ["VERFAHREN_EINSTELLUNGEN"],
        resolve: {
          "/konfigurationen/verfahren": GetKonfigurationenVerfahrenResolver,
          "/konfigurationen/codelisten": GetKonfigurationenCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/verfahren/{planID}": GetVerfahrenDetailResolver,
          "/verfahren/{planID}/verfahrensteilschritteuebersicht":
            GetVerfahrenVerfahrenteilsschritteuebersichtResolver,
          "/verfahren/{planID}/schedule": GetVerfahrenZeitplanungResolver,
        },
      },
    },
  ],
};
